import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import MarginImage from '@images/components/product-card/1_TypesOfProductCard_WithoutMargins.png';
import ContextualBadgeImage from '@images/components/product-card/3_ElementsWithin_ContextualBadge.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const ExampleBox = makeShortcode("ExampleBox");
const ProductCardHorizontalExample = makeShortcode("ProductCardHorizontalExample");
const ProductCardVerticalExample = makeShortcode("ProductCardVerticalExample");
const ImageWithCaption = makeShortcode("ImageWithCaption");
const BadgeExample = makeShortcode("BadgeExample");
const RatingExample = makeShortcode("RatingExample");
const PriceExample = makeShortcode("PriceExample");
const ButtonExample = makeShortcode("ButtonExample");
const ProductCardIconButtonExample = makeShortcode("ProductCardIconButtonExample");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-a-product-card"
            }}>{`How to use a product card`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#types-of-product-card"
            }}>{`Types of product card`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#elements-within-a-product-card"
            }}>{`Elements within a product card`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "how-to-use-a-product-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-a-product-card",
        "aria-label": "how to use a product card permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use a product card`}</h2>
    <p>{`Product cards provide users with all the important information about a product so they can make a considered decision. `}</p>
    <p>{`When using a product card:`}</p>
    <ul>
      <li parentName="ul">{`As a minimum the product card should contain an image, description and price`}</li>
      <li parentName="ul">{`Add further detail by using colour variants, brand logos, review ratings, or other information to help browsing and comparison`}</li>
      <li parentName="ul">{`Use buttons on product cards to encourage an action`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "types-of-product-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-product-card",
        "aria-label": "types of product card permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of product card`}</h2>
    <p>{`The product card comes in horizontal and vertical formats.`}</p>
    <h3>{`Horizontal`}</h3>
    <p>{`Below is an example of the horizontal product card. This is responsive and can be used for web or mobile.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <ProductCardHorizontalExample mdxType="ProductCardHorizontalExample" />
    </ExampleBox>
    <h3>{`Vertical`}</h3>
    <p>{`Below is an example of the vertical product card. This is responsive and can be used for web or mobile.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <ProductCardVerticalExample mdxType="ProductCardVerticalExample" />
    </ExampleBox>
    <h3>{`Cards with and without margins`}</h3>
    <p>{`Both horizontal and vertical cards can be used in layouts with margins and without margins.`}</p>
    <ImageWithCaption image={MarginImage} type="png" alt="Product card with and without margins" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "elements-within-a-product-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#elements-within-a-product-card",
        "aria-label": "elements within a product card permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Elements within a product card`}</h2>
    <p>{`Below is an overview of different types of elements used in a product card.`}</p>
    <TableOfContents label="Elements" mdxType="TableOfContents">
    
    <ul>
    <li><a className="ln-c-link" href="#image">Image</a></li>
    <li><a className="ln-c-link" href="#promotional-badges">Promotional badges</a></li>
    <li><a className="ln-c-link" href="#contextual-badges">Contextual Badges</a></li>
    <li><a className="ln-c-link" href="#descriptions">Description</a></li>
    <li><a className="ln-c-link" href="#ratings">Ratings</a></li>
    <li><a className="ln-c-link" href="#descriptions">Description</a></li>
    <li><a className="ln-c-link" href="#unit-price">Unit Price</a></li>
    <li><a className="ln-c-link" href="#button">Button</a></li>
    <li><a className="ln-c-link" href="#icon-button">Icon Button</a></li>
    </ul>
    </TableOfContents>
    <h3>{`Image`}</h3>
    <p>{`The image area is used to showcase a picture of the product, images should be clear not pixilated and not crop the product. Use swap instance to insert your image correctly.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <ProductCardVerticalExample mdxType="ProductCardVerticalExample" />
    </ExampleBox>
    <h3>{`Promotional badges`}</h3>
    <p>{`Badges can be used in product cards to highlight special features of the product you are highlighting.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <BadgeExample mdxType="BadgeExample" />
    </ExampleBox>
    <h3>{`Contextual badges`}</h3>
    <p>{`Contextual badges allow you to highlight products that have a ‘Nectar price’ available or the product on display is ‘Sponsored’. These contextual badges are built so that you can display either the ‘Nectar Price’ or ‘Sponsored’ badge above the image or below the image.`}</p>
    <ImageWithCaption image={ContextualBadgeImage} type="png" alt="Product card with contextual badge options" mdxType="ImageWithCaption" />
    <h3>{`Description`}</h3>
    <p>{`The description area is used to convey the name of the product and key highlights.`}</p>
    <h3>{`Ratings`}</h3>
    <p>{`Typically five stars and a numerical value assigned to the quality of the product.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <RatingExample mdxType="RatingExample" />
    </ExampleBox>
    <h3>{`Unit price`}</h3>
    <p>{`The product price can be shown in a variety of ways that include unit price, sale price and with or without loyalty points.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <PriceExample mdxType="PriceExample" />
    </ExampleBox>
    <h3>{`Button`}</h3>
    <p>{`If you need to link your product card to a product details page. You can do this by including a button at the base of the product card. Buttons let users take actions or make choices with a click or tap.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <ButtonExample mdxType="ButtonExample" />
    </ExampleBox>
    <h3>{`Icon Button`}</h3>
    <p>{`You should only use an icon button when the context makes it completely clear what the button's for, usually in the case of using an icon button ona product card could be for example a wish list.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <ProductCardIconButtonExample mdxType="ProductCardIconButtonExample" />
    </ExampleBox>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using accordions or give you a helping
  hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      